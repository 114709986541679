const COMPETENCY = [
    {
        title: "Web Publishing & Front-End Development",
        skills: [
            {
                name: "HTML5",
                level: 3,
            },
            {
                name: "CSS3",
                level: 3,
            },
            {
                name: "SCSS",
                level: 3,
            },
            {
                name: "JavaScript",
                level: 3,
            },
            {
                name: "jQuery",
                level: 3,
            },
            {
                name: "Bootstrap",
                level: 3,
            },
            {
                name: "Vue.js",
                level: 2,
            },
            {
                name: "Nuxt.js",
                level: 2,
            },
            {
                name: "React",
                level: 2,
            },
        ],
        colWidth: 6,
    },
    {
        title: "Design & Collaboration Tools",
        skills: [
            {
                name: "Figma",
                level: 2,
            },
            {
                name: "Photoshop",
                level: 2,
            },
            {
                name: "Illustrator",
                level: 2,
            },
            {
                name: "Zeplin",
                level: 1,
            },
            {
                name: "Notion",
                level: 3,
            },
            {
                name: "Slack",
                level: 3,
            },
            {
                name: "Git",
                level: 3,
            },
            {
                name: "Jira",
                level: 2,
            },
            {
                name: "Confluence",
                level: 2,
            },
        ],
        colWidth: 6,
    },
    {
        title: " Web Analytics & SEO Tools",
        skills: [
            {
                name: "Google Analytics",
                level: 2,
            },
            {
                name: "Naver Search Advisor",
                level: 2,
            },
            {
                name: "Microsoft Clarity",
                level: 2,
            },
        ],
        colWidth: 4,
    },
    {
        title: "Back-End & Data Management",
        skills: [
            {
                name: "PHP",
                level: 1,
            },
            {
                name: "MySQL",
                level: 1,
            },
            {
                name: "CodeIgniter",
                level: 1,
            },
        ],
        colWidth: 4,
    },
    {
        title: "Web Builder Platform",
        skills: [
            {
                name: "Imweb",
                level: 3,
            },
        ],
        colWidth: 4,
    },
];

export default COMPETENCY;
