const MENU = [
    {
        index: 0,
        id: "key-visual",
        title: "Main",
    },
    {
        index: 1,
        id: "history",
        title: "Work Experience",
    },
    {
        index: 2,
        id: "example",
        title: "Web APP Project",
    },
    {
        index: 3,
        id: "project",
        title: "Other Projects",
    },
    {
        index: 4,
        id: "core",
        title: "Core Competencies",
    },
    {
        index: 5,
        id: "personality",
        title: "Personality",
    },
    {
        index: 6,
        id: "contact",
        title: "Contact",
    },
];

export default MENU;
