import projectImg0 from "assets/img/renew/project/example1.jpg";
import projectImg1 from "assets/img/renew/project/project1.jpg";
import projectImg2 from "assets/img/renew/project/project2.jpg";
import projectImg3 from "assets/img/renew/project/project3.jpg";
import projectImg4 from "assets/img/renew/project/project4.jpg";
import projectImg5 from "assets/img/renew/project/project5.jpg";
import projectImg6 from "assets/img/renew/project/project6.jpg";
import projectImg7 from "assets/img/renew/project/project7.jpg";
import projectImg8 from "assets/img/renew/project/project8.jpg";
import projectImg9 from "assets/img/renew/project/project9.jpg";
import projectImg10 from "assets/img/renew/project/project10.jpg";
import projectImg11 from "assets/img/renew/project/project11.jpg";
import projectImg12 from "assets/img/renew/project/project12.jpg";
import projectImg13 from "assets/img/renew/project/project13.jpg";
import projectImg14 from "assets/img/renew/project/project14.jpg";
import projectImg15 from "assets/img/renew/project/project15.jpg";

const PROJECTS = [
    {
        name: "시사저널e - '신도시 30년'",
        type: "언론",
        imgSrc: projectImg0,
        link: "http://newtown.sisajournal-e.com/",
        duration: 18,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "포항공과대학교 기계공학과",
        type: "대학교",
        imgSrc: projectImg1,
        link: "https://me.postech.ac.kr/",
        duration: 14,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "서울여자대학교 교직지원센터",
        type: "대학교",
        imgSrc: projectImg2,
        link: "http://sweet.swu.ac.kr/",
        duration: 5,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "아주대학교 융합인재양성사업단",
        type: "대학교",
        imgSrc: projectImg3,
        link: "http://aimed.ajou.ac.kr/",
        duration: 4,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "웨스턴동물의료센터",
        type: "병원",
        imgSrc: projectImg4,
        link: "http://westernamc.com/",
        duration: 5,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "선진병원",
        type: "병원",
        imgSrc: projectImg5,
        link: "http://www.sunjin.or.kr/",
        duration: 5,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "피츠인솔 공식스토어",
        type: "기업",
        imgSrc: projectImg6,
        link: "http://phits.co.kr/",
        duration: 4,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "70%",
    },
    {
        name: "오메르데이스파",
        type: "서비스",
        imgSrc: projectImg7,
        link: "http://www.ormerdayspa.com/",
        duration: 5,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "한양3D팩토리",
        type: "기업",
        imgSrc: projectImg8,
        link: "http://hanyang3d.kr/",
        duration: 6,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "50%",
    },
    {
        name: "에치와이 프레시 매니저 채용",
        type: "기업",
        imgSrc: projectImg9,
        link: "https://fmrecruit.hy.co.kr/",
        duration: 6,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "예건건축",
        type: "건설/건축",
        imgSrc: projectImg10,
        link: "http://yekun-arc.com/",
        duration: 4,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "빅펀치 엔터테인먼트",
        type: "연예기획사",
        imgSrc: projectImg11,
        link: "http://bigpunchent.com/",
        duration: 4,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "100%",
    },
    {
        name: "아셈노인인권정책센터",
        type: "비영리단체",
        imgSrc: projectImg12,
        link: "http://www.asemgac.org/",
        duration: 14,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "70%",
    },
    {
        name: "법무법인 혜암",
        type: "법률",
        imgSrc: projectImg13,
        link: "http://www.gyeyanglaw.com/",
        duration: 5,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "50%",
    },
    {
        name: "관세법인 한주",
        type: "법률",
        imgSrc: projectImg14,
        link: "http://www.hjcustoms.co.kr",
        duration: 6,
        skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
        contribution: "70%",
    },
    // {
    //     name: "형사전문로펌 온강",
    //     type: "법률",
    //     imgSrc: projectImg15,
    //     link: "https://www.xn--939a100aw6drqg7pcjx7a.com/",
    //     duration: 5,
    //     skills: ["HTML5", "CSS3", "Javascript", "jQuery", "Bootstrap"],
    // },
];

export default PROJECTS;
