function Footer() {
    return (
        <footer id="footer">
            <div className="container">
                <div className="flex-row">
                    <div className="col">
                        <p className="des">
                            이 웹사이트는 포트폴리오를 목적으로 제작되었습니다.
                        </p>
                    </div>
                    <div className="col">
                        <p className="copy">
                            SANG-O LEE &copy; All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
